import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import '../../assets/js/bootstrap-datepicker.min.js';
import Api from "../../Api";
import * as constants from '../../Constants'

export default function CodeScript(params){
    window.document.title = params.title;
    let navigate = useNavigate();
	let [is_edit, setIsEdit] = useState(false);
	let editModal = useRef(null), deleteModal = useRef(null);
	let [cur_page, setCurPage] = useState(1);
	let [max_page, setMaxPage] = useState(1);
	let [spinner, setSpinner] = useState(false);
	let [is_logged_in, setIsLoggedIn] = useState(true);
	let [result, setResult] = useState({});
	let [button_spinner, setButtonSpinner] = useState(false);

    //Code Script form data
	let [hdnScriptId, sethdnScriptId] = useState('');
	let [drpProvider, setdrpProvider] = useState('');
	let [drpScriptName, setdrpScriptName] = useState('');
	let [txtScriptCode, settxtScriptCode] = useState('');
	
	const addScrapScript = () => {

	}
	const saveScrapScript = () => {

	}
	const resetForm = (form_element) => {
		var validator = $(form_element).validate();
		document.getElementById(form_element.replace(/#/g,'')).reset();
		validator.resetForm();

		/*sethdnSeriesId('');
		settxtSeriesProviderId('');
		setdrpProvider('');
		settxtSeriesName('');
		$('#txtSeriesStartDate').datepicker('destroy');
		settxtSeriesStartDate('');
		$('#txtSeriesEndDate').datepicker('destroy');
		settxtSeriesEndDate('');
		*/
		setButtonSpinner(false);

	}
    const prevPage = () =>{
		if(cur_page > 1){
			setCurPage(--cur_page)
		}else{ 
			setCurPage(1);
		}
	}
	const nextPage = () =>{
		if(cur_page < max_page){
			setCurPage(++cur_page)
		}else{
			setCurPage(max_page)
		}
	}
	
	const loadTable = () => {
		setSpinner(true);
		let page_length = $("#page_length").val();
		let data = {
			start : (cur_page-1) * page_length,
			length : page_length
		}
		Api('code-script', constants.prepareData('POST', data)).then((res) => {
			if(res.status !== 401){
				if(res.hasOwnProperty('list')){
					setResult(res);
					setMaxPage(res.list.total_pages);
					setCurPage((res.list.total_pages > cur_page ) ? res.list.total_pages : cur_page);
					setSpinner(false);
				}
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn(false);
			}
		});
	}
    
 	useEffect(() => {
		if(is_logged_in === false){
			navigate('/');
		}
	}, [is_logged_in, navigate])

	useEffect(() => {
		loadTable();
	}, [cur_page])
	/*const fillTableRow = (series, idx) => { 
		//console.log("row: ",series)
		return <>
			<tr key={series.series_id}>
				<td>{series.series_id}</td>
				<td>
					{result.provider[series.series_provider] }<br/><small>{series.provider_series_id}</small>
				</td>
				<td>{series.series_name} </td>
				<td>{constants.formatDate(series.series_start_date)}</td>
				<td>{constants.formatDate(series.series_end_date)}</td>
				<td>
					<div className="form-check form-switch">
						<input className="form-check-input" type="checkbox" name="switchSeriesStatus" role="switch" id="switchSeriesStatus" checked={(series.is_enabled ==1) ? "checked" : ''} onChange={(e)=>changeSeriesStatus(series.series_id,(series.is_enabled == 1) ? "0" : "1")} />
					</div>
				</td>
				<td>
					<button className="btn" data-bs-toggle="modal" data-bs-target="#add_edit_match_series" onClick={() => editSeries(series)}><i className="bi bot-edit text-primary"></i></button>
					<button className="btn" onClick={() => sethdnSeriesId(series.series_id)} data-bs-toggle="modal" data-bs-target="#delete_series"><i className="bi bot-trash text-danger-light"></i></button>
				</td>
			</tr>
		</>
	}*/
	return <>
		<section className="section">
			<div className="row mb-3">
				<div className="col-6 col-md-4">
					<h5 className="page-title">Code Script</h5>
				</div>
				
				<div className="col-12 col-md-2 d-grid mb-3">
					<button className="btn btn-info" data-bs-toggle="modal" data-bs-target="#add_edit_scrap_script" onClick={() => addScrapScript()}><i className="bi bi-plus-circle-fill pe-1"></i> Add new</button>
				</div>
			</div>
			<table className="table table-striped mb-0 datatable">
				<thead>
					<tr>
						<th>ID</th>
						<th>Provider</th>
						<th>Series Name</th>
						<th>Start Date</th>
						<th>End Date</th>
						<th>Status</th>
						<th width="115px">Actions</th>
					</tr>
				</thead>
				<tbody>
				{
					/*
					spinner === true ?
					<><tr><td colSpan={6} className="text-center"><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Loading Data..</td></tr></>
					:
					(spinner === false && (result != undefined && result.hasOwnProperty('list')) ) ?
					result.list.data.map((sub, idx) => 
						//fillTableRow(sub, idx)
					);
					: <><tr><td colSpan={7} className="text-center"> No data found</td></tr></> */
				}
					
					
				</tbody>
			</table>
			
			{
				result.hasOwnProperty('list') && 
				constants.pagination(result.list, cur_page, nextPage, prevPage, loadTable)
			}
		</section>

		<div className="modal fade" id="add_edit_scrap_script" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="Scrap Script" aria-hidden="true">
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">{(!is_edit) ? 'Add New Scrap Script' : 'Edit Scrap Script'}</h5>
						<button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" ref={editModal} onClick={ () => resetForm("#form_scrap_script") }><i className="bi bi-x-circle-fill"></i></button>
					</div>
					<div className="modal-body">
					<form autoComplete="off" id="form_scrap_script" method="post" acceptCharset="utf-8">
						<input type="hidden" name="hdnScriptId" id="hdnScriptId" defaultValue={hdnScriptId}/>
						
						<div className="modal-form">
							<div className="row mb-3">
								<div className="col-md-6">
									<label htmlFor="drpProvider" className="form-label">Select Provider</label>
									{
										result.hasOwnProperty('provider') &&
											constants.matchProvider(result.provider,setdrpProvider,drpProvider)
									}
								</div>					
								<div className="col-md-6">
									<label htmlFor="drpScriptName" className="form-label">Script Name</label>
									{
										constants.drpScriptName(setdrpScriptName,drpScriptName)
									}
								</div>							
							</div>
							<div className="row mb-3">				
								<div className="col-md-12">
									<label htmlFor="txtUrlValue" className="form-label">Script Url Value</label>
									<textarea rows="16" className="form-control" id="txtScriptCode" name="txtScriptCode" placeholder="Enter Url Value" defaultValue={txtScriptCode} onChange={(e) => settxtScriptCode(e.target.value)}></textarea>
								</div>							
							</div>
							<div className="row mb-3">				
								<div className="col-md-12">
									<label htmlFor="txtScriptCode" className="form-label">Script Code</label>
									<textarea rows="16" className="form-control" id="txtScriptCode" name="txtScriptCode" placeholder="Enter Script name" defaultValue={txtScriptCode} onChange={(e) => settxtScriptCode(e.target.value)}></textarea>
								</div>							
							</div>
						</div>
						{
							(button_spinner) ?
							<button type="button" className="btn btn-info ms-5 mb-4 px-4" disabled>
								<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
								Save Script
							</button>
							:
							<button type="button" className="btn btn-info ms-5 mb-4 px-4" onClick={ saveScrapScript }>Save Script</button>
						}
					</form>
					</div>
				</div>
			</div>
		</div>
		{/*
			constants.confirmDialog({
				id: "delete_series",
				title : "Delete Series", 
				action : "Delete",
				msg : "Are you sure to delete this series?",
				callback : deleteSeries,
				cancelConfirm : deleteModal,
			})*/
		}
	</>
}