import { Route, Routes, useNavigate } from 'react-router-dom';
import '../assets/css/custom.css'
import Login from './Login';
import Sidebar from './Common/Sidebar';
import Dashboard from './Pages/Dashboard';
import Matches from './Pages/Matches';
import site_logo from '../assets/images/logo/BotPricer.png';
import SystemError from './Pages/SystemError';
import $ from 'jquery';
import CodeScript from './Pages/CodeScript';
import MatchSeries from './Pages/MatchSeries';
import MasterSettings from './Pages/MasterSettings';
import UserSettings from './Pages/UserSettings';


function AdminApp() {
	
	let is_loggedin = false;
	if(localStorage.hasOwnProperty('admin_auth')){
		let admin_auth = JSON.parse(localStorage.getItem('admin_auth'));
		let time_now = Math.round((new Date()).getTime()/1000);
		if(admin_auth.expires > time_now){
			is_loggedin = true;
		}
	}
	const navigate = useNavigate();
	$(() => {
			
		$(window).on('storage', function(e){
			if(e.key === "admin_auth"){
				if(!localStorage.hasOwnProperty('admin_auth')){
					navigate("/")
				}else{
					navigate("dashboard");
				}
			}
		});
	});
	function toggleButton(event){
		$("#sidebar").toggleClass('active');
		$(".burger-btn").toggleClass('click');
	}
	if(!is_loggedin){
		return <>
			<Routes>
				<Route path="/" element={<Login logo={site_logo} title={"Admin Login"}/>}>
					<Route path="*" element={<Login logo={site_logo} title={"Admin Login"}/>} />
				</Route>
			</Routes>
		</>
	}else{
		return <>
			<Sidebar logo={site_logo} />
			<div id="main">
				<header className="mb-5 d-block d-lg-none">
					<button className="burger-btn btn btn-sm btn-light" onClick={(e) => toggleButton(e)}>
						<i className="bi bi-list fs-3"></i>
					</button>
				</header>
				<Routes>
					<Route path="/">
						<Route path="code-script" element={<CodeScript title={"Manage Code Script"}/>} />
						<Route path="matches" element={<Matches title={"Manage Matches"}/>} />
						<Route path="match-series" element={<MatchSeries title={"Manage Match Series"}/>} />
						<Route path="system-error" element={<SystemError title={"Manage System Errors"}/>} />
						<Route path="master-settings" element={<MasterSettings title={"Admin Master Settings"}/>} />
						<Route path="user-settings" element={<UserSettings title={"Admin Preference"}/>} />
						<Route path="dashboard" element={<Dashboard title={"Admin Dashboard"} />} />
						<Route path="" element={<Dashboard title={"Admin Dashboard"} />} />
					</Route>
				</Routes>
			</div>
		</>
	}
	
}

export default AdminApp;
