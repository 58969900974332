import { useEffect } from "react";
import $ from 'jquery'

export const API_URL = 'https://api.criclivescorecard.com/';
export const ADMIN_API_URL = API_URL + 'admin/';
export const STATIC_CONTENT = '';
export const SITE_PATH = window.location.protocol + "//" + window.location.host;
export const ADMIN_PATH = "/" ;
export const DEFAULT_RECORD_LIMIT = 10 ;
export const RECORD_LENGTH = [10, 25, 50, 100];
export const formatDate = (str_date, format = 'dd-mm-yyyy') => {
	
	let input = document.createElement("input");
	var d = new Date(str_date);
	let dt = $(input).datepicker({ format });
	dt.datepicker('update', d);
	
	return dt.datepicker('getFormattedDate')
}
export const prepareData = (method = 'GET', data = null, is_multipart = false) => {
	
	let headers = {};
	var access = JSON.parse(localStorage.getItem('admin_auth'));;

	if(is_multipart){
		headers = {
			'Content-Type' : 'multipart/form-data'
		}
	}else{
		headers = {
			'Content-Type' : 'application/json'
		}
	}
	
	if(access != null && access.hasOwnProperty('token')){
		headers['X-CRICLIVE-ACCESS-TOKEN'] =  access.token
	}
	let requestOptions = {method, headers};
	if(data != null){
		requestOptions.body = (is_multipart) ? data : JSON.stringify(data)
	}
	return requestOptions;
}

export const pagination = (page, cur_page, nextPage, prevPage, loadTable) => {
	
	return <>
		<div className="row mt-2">
			<div className="col-md-2">
				Showing { page.start } - {page.end} out of {page.total_records}
			</div>
			<div className="col-md-8 text-center">
				<button className={(cur_page <= 1) ? "btn btn-sm disabled" : "btn btn-sm"} onClick={prevPage}><i className="bi bot-page-previous text-primary mx-2"></i></button>
					{ (page.start == 0) ? 0 : cur_page }
				<button className={(cur_page === page.total_pages) ? "btn btn-sm disabled" : "btn btn-sm"} onClick={nextPage}><i className="bi bot-page-next text-primary mx-2"></i></button>
			</div>
			<div className="col-md-2 text-end">
				<select id="page_length" defaultValue={DEFAULT_RECORD_LIMIT} onChange={loadTable}>
					{
						RECORD_LENGTH.map((len, idx) =>
							<option key={idx} value={len} > {len} per page</option>
						)
					}
				</select>
			</div>
		</div>
	</>
}



export const confirmDialog = (params) => {
	//Are you sure you want to delete this?
	return <>
		<div className="modal fade" id={params.id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={params.title} aria-hidden="true">
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">{params.title}</h5>
					</div>
					<div className="modal-body">
						<div className="modal-form">
							<label className="mx-4 mb-5">{params.msg}</label>
						</div>
						<button type="button" data-bs-dismiss="modal" aria-label="Close" className="btn btn-info ms-5 mb-4 px-5" ref={params.cancelConfirm}>Cancel</button>
						{
							(params.colors == "success") ? <button type="button" className="btn btn-success ms-3 mb-4 px-5" onClick={() => params.callback()}>{params.action}</button> : <button type="button" className="btn btn-danger ms-3 mb-4 px-5" onClick={() => params.callback()}>{params.action}</button>
						}
						
					</div>
				</div>
			</div>
		</div>
	</>
}

export const matchProvider = (provider, callback,selected = null, props = {}) => {
	
	//console.log("cl",selected);
	return <>
		<select  name={props.hasOwnProperty('name')  ? props.name : ""}  className="form-select" value={selected !== null ? selected :''} onChange={({target: {value}}) => {
			callback(value)
		}}>
			<option value="">Match Provider</option>
			{
				provider.map((match_provider, idx) =>
					<option key={idx} value={idx} > {match_provider}</option>
				)
			}
		</select>
	</>
}
export const drpSeriesList = (series_list, callback,selected = null, props = {}) => {
	
	return <>
		<select name={props.hasOwnProperty('name') ? props.name : ""} className="form-select" value={selected !== null ? selected :''} onChange={({target: {value}}) => {
			callback(value)
		}}>
			<option value="">Match Series</option>
			{
				series_list.map((series, idx) =>
					//console.log("series_list",series)
					<option key={idx} value={series.series_id} > {series.series_name}</option>
				)
			}
		</select>
	</>
}

export const drpMatchType = (callback, selected, props = {}) => {

	return <>
		<select name={props.hasOwnProperty('name') ? props.name : ""} className="form-select" value={selected !== null ? selected :''} onChange={({target: {value}}) => {
			callback(value)
		}}>
			<option value="">Match Type</option>
			<option value="TEST">TEST</option>
			<option value="ODI">ODI</option>
			<option value="T20">T20</option>
			<option value="T10">T10</option>
		</select>
	</>
}
export const drpMatchStatus = (callback, selected, props = {}) => {

	return <>
		<select name={props.hasOwnProperty('name') ? props.name : ""} className="form-select" value={selected !== null ? selected :''} onChange={({target: {value}}) => {
			callback(value)
		}}>
			<option value="">Match Status</option>
			<option value="0">0-Finished</option>
			<option value="1">1-Upcoming</option>
			<option value="2">2-Live</option>
		</select>
	</>
}
export const drpScriptName = (callback, selected, props = {}) => {

	return <>
		<select name={props.hasOwnProperty('name') ? props.name : ""} className="form-select" value={selected !== null ? selected :''} onChange={({target: {value}}) => {
			callback(value)
		}}>
			<option value="">Script Name</option>
			<option value="Live">Live</option>
			<option value="Scorecard">Scorecard</option>
		</select>
	</>
}
export const convertToSqlDate = (date) => {
	
	var str = date.getFullYear();
	str = str + '-' + (date.getMonth()+1);
	str = str + '-' + (date.getDate()/10).toFixed(1);
	str = str + ' ' + (date.getHours()/10).toFixed(1);
	str = str + ':' + (date.getMinutes()/10).toFixed(1) + ':00';
	str = str.replace(/\./g, '')
	return str;
	
}

export const todayDate = () => {
	var date = new Date();
	var str = (date.getDate()/10).toFixed(1);
	str = str + '-' + (date.getMonth()+1);
	str = str + '-' + date.getFullYear();
	//str = str + ' ' + (date.getHours()/10).toFixed(1);
	//str = str + ':' + (date.getMinutes()/10).toFixed(1) + ':00';
	str = str.replace(/\./g, '')
	return str;
	
}