import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import '../../assets/js/bootstrap-datepicker.min.js';
import Api from "../../Api";
import * as constants from '../../Constants';

export default function MatchSeries(params){
    window.document.title = params.title;
    let navigate = useNavigate();
	let matchSeriesModal = useRef(null);
	let [is_edit, setIsEdit] = useState(false);
	let editModal = useRef(null), deleteModal = useRef(null);
	let [cur_page, setCurPage] = useState(1);
	let [max_page, setMaxPage] = useState(1);
	let [spinner, setSpinner] = useState(false);
	let [is_logged_in, setIsLoggedIn] = useState(true);
	let [result, setResult] = useState({});
	let [filterProvider, setfilterProvider] = useState('');
	let [search, setSearch] = useState('');
	let [button_spinner, setButtonSpinner] = useState(false);

	//Code Script form data
	let [hdnSeriesId, sethdnSeriesId] = useState('');
	let [txtSeriesProviderId, settxtSeriesProviderId] = useState('');
	
	let [drpProvider, setdrpProvider] = useState('');
	let [txtSeriesName, settxtSeriesName] = useState('');
	let [txtSeriesStartDate, settxtSeriesStartDate] = useState('');
	let [txtSeriesEndDate, settxtSeriesEndDate] = useState('');

	const addSeries = () => {

	}

	const saveSeries = () => {
		if($("#form_match_series").valid()){
			setButtonSpinner(true)
			let data = {
				hdnSeriesId, txtSeriesProviderId, drpProvider, txtSeriesName, txtSeriesStartDate, txtSeriesEndDate, 
			}
			//console.log(data);
			
			Api("match-series/save", constants.prepareData('POST', data)).then((res) => {
				setButtonSpinner(false);
				//console.log(res);
				if(res.status !== 401){
					editModal.current.click();
					resetForm("#form_match_series");
					loadTable();
				}else{
					localStorage.removeItem('admin_auth');
					setIsLoggedIn(false);
				}
			});
		}
	}
	
	const editSeries = (series = null) => {
		//console.log(series);
		setIsEdit((series !== null));
		sethdnSeriesId((series !== null) ? series.series_id : '');
		settxtSeriesProviderId((series !== null) ? series.provider_series_id : '');
		settxtSeriesName((series !== null) ? series.series_name : '');

		setdrpProvider((series !== null) ? series.series_provider : '');
		//console.log(series.series_provider);
		
		settxtSeriesStartDate((series !== null) ? constants.formatDate(series.series_start_date) : '');
		$('#txtSeriesEndDate').datepicker('destroy');
		if(series !== null && series.series_end_date != ''){
			var dateString = new Date(series.series_start_date);
			
			$('#txtSeriesEndDate').datepicker({
				autoclose : true,
				format: "dd-mm-yyyy",
				startDate: dateString,
			});
		}
		settxtSeriesEndDate((series !== null) ? constants.formatDate(series.series_end_date) : '');
	}
	
	const changeSeriesStatus = (seriesid = null,status) => {
		console.log(seriesid,status);
		let data = {
			seriesid,status,
		}
		
		Api("match-series/change-status", constants.prepareData('POST', data)).then((res) => {
			//console.log(res);
			//deleteModal.current.click();
			if(res.status !== 401){
				loadTable();
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn(false);
			}
		});

	}
	
	const refreshSeriesMatches = (seriesid = null,provider_series_id) => {
		if(seriesid != '' && provider_series_id != '')
		{
			let data = {
				seriesid,provider_series_id,
			}
			
			Api("match-series/refresh-series-matches", constants.prepareData('POST', data)).then((res) => {
				if(res.status !== 401){
					loadTable();
				}else{
					localStorage.removeItem('admin_auth');
					setIsLoggedIn(false);
				}
			});
		}
	}

	const deleteSeries = () => {
		let data = {
			hdnSeriesId,
		}
		
		Api("match-series/delete", constants.prepareData('POST', data)).then((res) => {
			//console.log(res);
			deleteModal.current.click();
			if(res.status !== 401){
				loadTable();
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn(false);
			}
		});
	}
	const resetForm = (form_element) => {
		var validator = $(form_element).validate();
		document.getElementById(form_element.replace(/#/g,'')).reset();
		validator.resetForm();

		sethdnSeriesId('');
		settxtSeriesProviderId('');
		setdrpProvider('');
		settxtSeriesName('');
		$('#txtSeriesStartDate').datepicker('destroy');
		settxtSeriesStartDate('');
		$('#txtSeriesEndDate').datepicker('destroy');
		settxtSeriesEndDate('');
		setButtonSpinner(false);

	}
	$(() => {

		$('#txtSeriesStartDate').datepicker({
			autoclose : true,
			format: "dd-mm-yyyy",
		}).on("changeDate", function(){
			settxtSeriesStartDate($('#txtSeriesStartDate').datepicker('getFormattedDate'));
			var dateString = $('#txtSeriesStartDate').datepicker('getFormattedDate').split('-');
			
			$('#txtSeriesEndDate').datepicker('destroy').val('');

			$('#txtSeriesEndDate').datepicker({
				autoclose : true,
				format: "dd-mm-yyyy",
				startDate: new Date(Date.UTC(dateString[2], dateString[1]-1, parseInt(dateString[0])+1 ))
			}).on("changeDate", function(){
				settxtSeriesEndDate($('#txtSeriesEndDate').datepicker('getFormattedDate'));
			});
		});

		if(txtSeriesStartDate != ''){
			
			$('#txtSeriesEndDate').datepicker({
				autoclose : true,
				format: "dd-mm-yyyy",
				startDate: new Date(txtSeriesStartDate)
			}).on("changeDate", function(){
				settxtSeriesEndDate($('#txtSeriesEndDate').datepicker('getFormattedDate'));
			});
		}

		$("#form_match_series").validate({
			rules: {
				txtSeriesProviderId : {
					required: true,
					number:true
				},
				txtSeriesName : {
					required: true,
				},
				txtSeriesStartDate : {
					required: true,
				},
				txtSeriesEndDate : {
					required: true,
				},
			},
			errorElement : 'div',
			errorPlacement: function(error, element) {
				//console.log(element);
				error.appendTo( element.parent('div') );
				error.addClass('invalid-feedback');
			},
			errorClass: "is-invalid",

		});		
	});
	const prevPage = () =>{
		if(cur_page > 1){
			setCurPage(--cur_page)
		}else{ 
			setCurPage(1);
		}
	}
	const nextPage = () =>{
		if(cur_page < max_page){
			setCurPage(++cur_page)
		}else{
			setCurPage(max_page)
		}
	}
	
	const loadTable = () => {
		setSpinner(true);
		let page_length = $("#page_length").val();
		if(page_length == undefined){
			page_length= constants.DEFAULT_RECORD_LIMIT;
		}
		let data = {
			filterProvider,
			search,
			start : (cur_page-1) * page_length,
			length : page_length
		}
		Api('match-series', constants.prepareData('POST', data)).then((res) => {
			if(res.status !== 401){
				if(res.hasOwnProperty('list')){
					setResult(res);
					setMaxPage(res.list.total_pages);
					setCurPage((res.list.total_pages != 0 && res.list.total_pages < cur_page ) ? res.list.total_pages : cur_page);
					setSpinner(false);
				}
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn(false);
			}
		});
	}
	
 	useEffect(() => {
		if(is_logged_in === false){
			navigate('/');
		}
	}, [is_logged_in, navigate])

	
	useEffect(() => {
		loadTable();
	}, [cur_page, filterProvider, search])

	const fillTableRow = (series, idx) => { 
		//console.log("row: ",series)
		return <>
			<tr key={series.series_id}>
				<td>{series.series_id}</td>
				<td>
					{result.provider[series.series_provider] }<br/><small>{series.provider_series_id}</small>
				</td>
				<td>{series.series_name}{new Date().toLocaleString()} </td>
				<td>{constants.formatDate(series.series_start_date)}</td>
				<td>{constants.formatDate(series.series_end_date)}</td>
				<td>
					<div className="form-check form-switch">
						<input className="form-check-input" type="checkbox" name="switchSeriesStatus" role="switch" id="switchSeriesStatus" checked={(series.is_enabled ==1) ? "checked" : ''} onChange={(e)=>changeSeriesStatus(series.series_id,(series.is_enabled == 1) ? "0" : "1")} />
					</div>
				</td>
				<td>
					{
						(series.series_provider == 0) &&
						<button className="btn text-default" onClick={(e)=>refreshSeriesMatches(series.series_id,series.provider_series_id)} ><i className="bi bi-arrow-clockwise"></i></button>
					}{series.total_match}
				</td>
				<td>
					<button className="btn" data-bs-toggle="modal" data-bs-target="#add_edit_match_series" onClick={() => editSeries(series)}><i className="bi bot-edit text-primary"></i></button>
					<button className="btn" onClick={() => sethdnSeriesId(series.series_id)} data-bs-toggle="modal" data-bs-target="#delete_series"><i className="bi bot-trash text-danger-light"></i></button>				
					
				</td>
			</tr>
		</>
	}
	return <>
		<section className="section">
			<div className="row mb-3">
				<div className="col-6 col-md-4">
					<h5 className="page-title">Match Series</h5>
				</div>
				<div className="col-md-3">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<input type="text" className="form-control" placeholder="Search by name" id="search_by_name" onKeyUp={(e) => {setSearch(e.target.value)}}/>
							<div className="form-control-icon">
								<i className="bi bi-search f-color"></i>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-3 mb-3">
					{
						result.hasOwnProperty('provider') &&
							constants.matchProvider(result.provider, setfilterProvider, filterProvider)
					}
				</div>
				<div className="col-12 col-md-2 d-grid mb-3">
					<button className="btn btn-info" data-bs-toggle="modal" data-bs-target="#add_edit_match_series" onClick={() => addSeries()}><i className="bi bi-plus-circle-fill pe-1"></i> Add new</button>
				</div>
			</div>
			<table className="table table-striped mb-0 datatable">
				<thead>
					<tr>
						<th>ID</th>
						<th>Provider</th>
						<th>Series Name</th>
						<th>Start Date</th>
						<th>End Date</th>
						<th>Status</th>
						<th>Load Matches</th>
						<th width="115px">Actions</th>
					</tr>
				</thead>
				<tbody>
				{
					spinner === true ?
					<><tr><td colSpan={8} className="text-center"><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Loading Data..</td></tr></>
					:
					(spinner === false && (result.hasOwnProperty('list') && result.list.total_records > 0) ) ?
						result.list.data.map((sub, idx) => 
							fillTableRow(sub, idx)
						)
					: <><tr><td colSpan={8} className="text-center"> No data found</td></tr></>
				}
					
					
				</tbody>
			</table>
			
			{
				result.hasOwnProperty('list') && 
				constants.pagination(result.list, cur_page, nextPage, prevPage, loadTable)
			}
		</section>

		<div className="modal fade" id="add_edit_match_series" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby=" Match Series" aria-hidden="true">
			<div className="modal-dialog modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">{(!is_edit) ? 'Add New Match Series' : 'Edit Match Series'}</h5>
						<button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" ref={editModal} onClick={ () => resetForm("#form_match_series") }><i className="bi bi-x-circle-fill"></i></button>
					</div>
					<div className="modal-body">
					<form autoComplete="off" id="form_match_series" method="post" acceptCharset="utf-8">
						<input type="hidden" name="hdnSeriesId" id="hdnSeriesId" defaultValue={hdnSeriesId}/>
						
						<div className="modal-form">
							<div className="m-4">
								<label htmlFor="txtSeriesProvider" className="form-label">Series Provider Id</label>
								<input type="text" className="form-control" id="txtSeriesProviderId" name="txtSeriesProviderId" placeholder="Enter a Series Provider Id" defaultValue={txtSeriesProviderId} onChange={(e) => settxtSeriesProviderId(e.target.value)}/>
							</div>
							<div className="m-4">
								<label htmlFor="drpSeriesProvider" className="form-label">Select Series Provider</label>
								{
									result.hasOwnProperty('provider') &&
										constants.matchProvider(result.provider,setdrpProvider,drpProvider)
								}
							</div>								
							<div className="m-4">
								<label htmlFor="txtSeriesName" className="form-label">Series Name</label>
								<input type="text" className="form-control" id="txtSeriesName" name="txtSeriesName" placeholder="Enter a Series name" defaultValue={txtSeriesName} onChange={(e) => settxtSeriesName(e.target.value)}/>
							</div>
							<div className="m-4">
								<label htmlFor="txtSeriesStartDate" className="form-label">Series Start Date</label>
								<div className="form-group has-icon-left">
									<div className="position-relative">
										<input type="text" className="form-control" id="txtSeriesStartDate" name="txtSeriesStartDate" placeholder="Enter a Series Start Date" defaultValue={txtSeriesStartDate} onChange={(e) => settxtSeriesStartDate(e.target.value)}/>
										<div className="form-control-icon">
											<i className="bi bot-calendar"></i>
										</div>
									</div>
								</div>
							</div>
							<div className="m-4">
								<label htmlFor="txtSeriesEndDate" className="form-label">Series End Date</label>
								<div className="form-group has-icon-left">
									<div className="position-relative">
										<input type="text" className="form-control" id="txtSeriesEndDate" name="txtSeriesEndDate" placeholder="Enter a Series End Date" defaultValue={txtSeriesEndDate} onChange={(e) => settxtSeriesEndDate(e.target.value)}/>
										<div className="form-control-icon">
											<i className="bi bot-calendar"></i>
										</div>
									</div>
								</div>
							</div>
						</div>
						{
							(button_spinner) ?
							<button type="button" className="btn btn-info ms-5 mb-4 px-4" disabled>
								<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
								Save Series
							</button>
							:
							<button type="button" className="btn btn-info ms-5 mb-4 px-4" onClick={ saveSeries }>Save Series</button>
						}
					</form>
					</div>
				</div>
			</div>
		</div>
		{
			constants.confirmDialog({
				id: "delete_series",
				title : "Delete Series", 
				action : "Delete",
				msg : "Are you sure to delete this series?",
				callback : deleteSeries,
				cancelConfirm : deleteModal,
			})
		}
	</>
}