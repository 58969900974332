import Notify from './Notify';
import $ from 'jquery';
import 'jquery-validation';
import * as constants from './Constants';

$(function(){
	$.validator.addMethod("empty", function(value, element) {
		//console.log("trim", value.trim().length);
		return this.optional(element) || ($.trim(value) === '' ? false : true);
	}, "This field is required");
	
	// this function is to accept Strong password
	$.validator.addMethod("pwcheck", function(value) {
		let pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%\^&\*\?])(?=.{8,})/;
			return pattern.test(value)
		},
		function() {
			return 'Use minimum 8 characters with at least one number, one uppercase, one lowercase and only special character from [!@#$%^&*?].';
		}
	);
	
	// this function is to accept only email
	$.validator.addMethod("emailcheck", function(value) {
		let pattern = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
			return pattern.test(value)
		},
		function() {
			return 'Please enter a valid email.';
		}
	);
})


export default async function Api(path, params){

	try{
		
		let url =  constants.ADMIN_API_URL;
		let res = await fetch( url + path, params);

		let status = res.status;

		res = await res.json();
		res.status = status;
		//console.log(res);
		if(res.hasOwnProperty('messages')){
			if(res.status === 400){
				try{
					let errors = JSON.parse(res.messages.error);
					//console.log(errors);
					errors.map((msg,idx) => {
						$('#'+ msg.field).addClass('is-invalid');
						let notify = {
							message : msg.message,
							icon : "bi bi-x-circle-fill me-3"
						};
						Notify(notify);
					});
				}catch(e){
					let notify = {
						message : res.messages.error,
						icon : "bi bi-x-circle-fill me-3"
					};
					Notify(notify);
				}
				
			}else{
				let notify = {
					message : res.messages.error,
					icon : "bi bi-x-circle-fill me-3"
				};
				Notify(notify);
			}
		}else if(res.hasOwnProperty('notify')){
			//sessionStorage.setItem('notify',JSON.stringify(res.notify));
			Notify(res.notify.data, res.notify.settings);
		}
		return res;
	}catch(e){
		console.log(e);
	}
}