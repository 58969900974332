import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import '../../assets/js/bootstrap-datepicker.min.js';
import Api from "../../Api";
import * as constants from '../../Constants'

function Dashboard(params){
	window.document.title = params.title;

	let [cur_page, setCurPage] = useState(1);
	let [max_page, setMaxPage] = useState(1);
	let [spinner, setSpinner] = useState(false);
	let [is_logged_in, setIsLoggedIn] = useState(true);
	let [result, setResult] = useState({});
	let [order_status, setOrderStatus] = useState('all');

	let navigate = useNavigate();

	const prevPage = () =>{
		if(cur_page > 1){
			setCurPage(--cur_page)
		}else{ 
			setCurPage(1);
		}
	}
	const nextPage = () =>{
		if(cur_page < max_page){
			setCurPage(++cur_page)
		}else{
			setCurPage(max_page)
		}
	}
	
	const loadTable = () => {
		setSpinner(true);
		let page_length = $("#page_length").val();
		if(page_length == undefined)
			page_length = constants.DEFAULT_RECORD_LIMIT;
		let data = {
			start : (cur_page-1) * page_length,
			length : page_length,
			status : order_status, 
		}
		Api('auth', constants.prepareData('POST', data)).then((res) => {
			
			if(res.status !== 401){
				if(res.hasOwnProperty('list')){
					setResult(res);
					setMaxPage(res.list.total_pages);
					setCurPage((cur_page > res.list.total_pages) ? res.list.total_pages : cur_page);
					setSpinner(false);
				}
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn(false);
			}
		});
	}

	useEffect(() => {
		loadTable();
	}, [order_status]);

	useEffect(() => {
		if(is_logged_in === false){
			navigate('/admin/');
		}
	}, [is_logged_in, navigate])

	useEffect(() => {
		loadTable();
	}, [cur_page])


	const fillTableRow = (order) => {
		
		console.log(order.order_status );
		if(order.order_status == 2){
			var btn = <button type="button" className="btn" data-bs-toggle="tooltip" data-bs-title="Hold Order">
				<i className="bi bot-resume text-warning"></i>
			</button> 
		}else {
			var btn = <button type="button" className="btn" data-bs-toggle="tooltip" data-bs-title="Hold Order">
				<i className="bi bot-hold text-warning"></i>
			</button> 
		}
		return <>
			<tr>
				<td>{order.user_order_id}</td>
				<td>{order.total_amount}</td>
				<td>Montly valid</td>
				<td>{order.last_name}</td>
				<td>{constants.formatDate(order.order_date)}</td>
				<td>{constants.formatDate(order.subscription_renewal_date, 'dd-mm-yyyy') }</td>
				<td>
					<button type="button" className="btn btn-edit" data-bs-toggle="tooltip" data-bs-title="Edit Order">
						<i className="bi bot-edit-fill text-primary"></i>
					</button>
					<button type="button" className="btn" data-bs-toggle="tooltip" data-bs-title="Refund">
						<i className="bi bot-refund text-info"></i>
					</button>
					{ (order.order_status == 2) ? <button type="button" className="btn" data-bs-toggle="tooltip" data-bs-title="Hold Order">
				<i className="bi bot-resume text-warning"></i>
			</button>  : <button type="button" className="btn" data-bs-toggle="tooltip" data-bs-title="Hold Order">
				<i className="bi bot-hold text-warning"></i>
			</button> }
					<button type="button" className="btn" data-bs-toggle="tooltip" data-bs-title="Delete Order">
						<i className="bi bot-trash text-danger-light"></i>
					</button>
				</td>
			</tr>
		</>
	}

	return <>
	<section className="section">
			<div className="row mb-3">
		<div className="col-6 col-md-3 col-lg-9">
			<h5 className="page-title">Orders</h5>
		</div>
		<div className="col-6 col-md-3 col-lg-3 d-grid">
			<div className="form-group has-icon-left">
				<div className="position-relative">
					<input type="text" className="form-control bg-textbox bot-calendar" placeholder="Select date range" id="txtDashboardDateRange" name="txtDashboardDateRange"/>
					<div className="form-control-icon">
						<i className="bi bot-calendar text-primary"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div className="row mb-3">
		<div className="col-md-4">
			<div className="card dashboard">
				<div className="card-body">
					<h5 className="">Gross revenue</h5>
					<div className="">
						<span className="text-success revenue-amount mb-2">$15,321.01</span>
						<span className="badge rounded-pill bg-light-success mx-2 align-text-bottom">+15.2%</span>
					</div>
					<p className="card-text card-description">Previous: <b>$12,201.14</b></p>
				</div>
			</div>
		</div>
		<div className="col-md-4">
			<div className="card dashboard">
				<div className="card-body">
					<h5 className="">Daily sales</h5>
					<div className="">
						<span className="text-danger revenue-amount mb-2">$632.02</span>
						<span className="badge rounded-pill bg-light-danger mx-2 align-text-bottom">-1.52%</span>
					</div>
					<p className="card-text card-description">Previous: <b>$721.10</b></p>
				</div>
			</div>
		</div>
		<div className="col-md-4">
			<div className="card dashboard">
				<div className="card-body">
					<h5 className="">Orders today</h5>
					<div className="">
						<span className="text-success revenue-amount mb-2">15</span>
						<span className="badge rounded-pill bg-light-success mx-2 align-text-bottom">+5.18%</span>
					</div>
					<p className="card-text card-description">Previous: <b>12</b></p>
				</div>
			</div>
		</div>
	</div>
	<div className="row mt-4">
		<div className="col-6 col-md-3 col-lg-3">
			<div className="form-group has-icon-left">
				<div className="position-relative">
					<input type="text" className="form-control bg-textbox bot-calendar" placeholder="Select date range" id="txtDataTableDateRange" name="txtDataTableDateRange"/>
					<div className="form-control-icon">
						<i className="bi bot-calendar text-primary"></i>
					</div>
				</div>
			</div>
		</div>
		<div className="col-6 col-md-3 col-lg-6">
			&nbsp;
		</div>
		<div className="col-6 col-md-3 col-lg-3">
			<div className="form-group has-icon-left">
				<div className="position-relative">
					<input type="text" className="form-control bg-textbox" placeholder="Search by name" id="txtDataTableSearch" name="txtDataTableSearch"/>
					<div className="form-control-icon">
						<i className="bi bi-search f-color text-primary"></i>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div className="row">
		<div className="col-12">
			
			<ul className="nav nav-tabs text-center" role="tablist">
				<li className="nav-item" role="presentation">
					<a className="nav-link active" id="all-orders-tab" data-bs-toggle="tab" data-bs-target="#all-orders" type="button" role="tab" aria-controls="all-orders" aria-selected="true" onClick={ () => setOrderStatus('all')}>All Orders</a>
				</li>
				<li className="nav-item" role="presentation">
					<a className="nav-link" id="active-orders-tab" data-bs-toggle="tab" data-bs-target="#active-orders" type="button" role="tab" aria-controls="active-orders" aria-selected="false" onClick={ () => setOrderStatus('active')}>Active Orders</a>
				</li>
				<li className="nav-item" role="presentation">
					<a className="nav-link" id="on-hold-tab" data-bs-toggle="tab" data-bs-target="#on-hold" type="button" role="tab" aria-controls="onhold" aria-selected="false"  onClick={ () => setOrderStatus('hold')}>On Hold</a>
				</li>
				<li className="nav-item" role="presentation">
					<a className="nav-link" id="refunded-tab" data-bs-toggle="tab" data-bs-target="#refunded" type="button" role="tab" aria-controls="refunded" aria-selected="false" onClick={ () => setOrderStatus('refunded')}>Refunded</a>
				</li>
				<li className="nav-item" role="presentation">
					<a className="nav-link" id="failed-tab" data-bs-toggle="tab" data-bs-target="#failed" type="button" role="tab" aria-controls="failed" aria-selected="false"  onClick={ () => setOrderStatus('failed')}>Failed</a>
				</li>
				<li className="nav-item" role="presentation">
					<a className="nav-link" id="cancelled-tab" data-bs-toggle="tab" data-bs-target="#cancelled" type="button" role="tab" aria-controls="cancelled" aria-selected="false" onClick={ () => setOrderStatus('cancelled')}>Cancelled</a>
				</li>
				<li className="nav-item" role="presentation">
					<a className="nav-link" id="expired-trial-tab" data-bs-toggle="tab" data-bs-target="#expired-trial" type="button" role="tab" aria-controls="expired-trial" aria-selected="false"  onClick={ () => setOrderStatus('trial_expired')}>Expired Trial</a>
				</li>
				<li className="nav-item" role="presentation">
					<a className="nav-link" id="expired-orders-tab" data-bs-toggle="tab" data-bs-target="#expired-orders" type="button" role="tab" aria-controls="expired-orders" aria-selected="false"  onClick={ () => setOrderStatus('expired')}>Expired Orders</a>
				</li>
			</ul>
		</div>
		<div className="col-12 table-responsive">
			<table className="table table-striped mb-0 datatable">
					<thead>
						<tr>
							<th width="15%">Order No</th>
							<th width="5%">Total</th>
							<th>Staus</th>
							<th>Customer</th>
							<th>Date</th>
							<th>Renewal Date</th>
							<th width="20%">Actions</th>
						</tr>
					</thead>
					<tbody>
						
					{
						spinner === true ?
						<><tr><td colSpan={7} className="text-center"><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Loading Data..</td></tr></>
						:
						(spinner === false && (result != undefined && result.hasOwnProperty('list')) ) ?
						result.list.data.map((sub, idx) => 
							fillTableRow(sub, idx)
						)
						: <><tr><td colSpan={7} className="text-center"> No data found</td></tr></>
					}
						
						
					</tbody>
				</table>
				
				{
					result.hasOwnProperty('list') && 
					constants.pagination(result.list, cur_page, nextPage, prevPage, loadTable)
				}
			</div>
		</div>
	</section>
	</>

}

export default Dashboard;