import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';

import '../../assets/css/bootstrap-datetimepicker.min.css';
import '../../assets/js/moment.min.js';
import '../../assets/js/bootstrap-datetimepicker.min.js';
import Api from "../../Api";
import * as constants from '../../Constants'
import DatePicker from "rsuite/DatePicker";


export default function Matches(params){
	window.document.title = params.title;
	let navigate = useNavigate();
	let matchModal = useRef(null);
	let [is_edit, setIsEdit] = useState(false);
	let editModal = useRef(null), deleteModal = useRef(null);
	let [cur_page, setCurPage] = useState(1);
	let [max_page, setMaxPage] = useState(1);
	let [spinner, setSpinner] = useState(false);
	let [is_logged_in, setIsLoggedIn] = useState(true);
	let [result, setResult] = useState({});
	let [filterDate, setfilterDate] = useState(constants.todayDate());
	let [filterProvider, setfilterProvider] = useState('');
	let [filterMatchSeries, setfilterMatchSeries] = useState('');
	let [search, setSearch] = useState('');
	let [button_spinner, setButtonSpinner] = useState(false);

	//Code Script form data
	let [hdnMatchId, sethdnMatchId] = useState('');
	let [txtCricproMatchId, settxtCricproMatchId] = useState('');
	let [txtMatchUrl, settxtMatchUrl] = useState('');
	let [txtTeamImageA, settxtTeamImageA] = useState('');
	let [txtTeamImageB, settxtTeamImageB] = useState('');
	
	let [drpProvider, setdrpProvider] = useState('');
	let [drpMatchSeries, setdrpMatchSeries] = useState('');
	let [txtMatchName, settxtMatchName] = useState('');
	let [txtTeamA, settxtTeamA] = useState('');
	let [txtTeamB, settxtTeamB] = useState('');
	let [txtMatchType, settxtMatchType] = useState('');
	let [txtMatchDate, settxtMatchDate] = useState(null);
	let [txtMatchResult, settxtMatchResult] = useState('');
	let [drpMatchStatus, setdrpMatchStatus] = useState(false);
	let [txtMatchVenue, settxtMatchVenue] = useState('');

	const addMatch = () => {		

	}
	const saveMatch = () => {	
		if($("#form_match").valid()){
			setButtonSpinner(true)
			let data = {
				hdnMatchId, txtCricproMatchId, txtMatchUrl, drpProvider, drpMatchSeries, txtMatchName, txtTeamA, txtTeamB, txtMatchType, txtMatchDate : constants.convertToSqlDate(txtMatchDate), txtMatchResult, drpMatchStatus, txtMatchVenue, txtTeamImageA, txtTeamImageB
			}
			//console.log(data);
			
			Api("matches/save", constants.prepareData('POST', data)).then((res) => {
				setButtonSpinner(false);
				//console.log(res);
				if(res.status !== 401){
					editModal.current.click();
					resetForm("#form_match");
					loadTable();
				}else{
					localStorage.removeItem('admin_auth');
					setIsLoggedIn(false);
				}
			});
		}
	}
	const dateInput = () => {
		return <>
				<DatePicker 
				id="txtMatchDate"
				format="dd-MM-yyyy hh:mm aa" 
				placeholder="Match Date"
				value ={ (txtMatchDate != null) ? txtMatchDate : null}
				block
				onChange={(d) => settxtMatchDate(d)}
				placement="auto"/>
			</>
	}

	
	const editMatch = (match = null) => {
		
		setIsEdit((match !== null));
		sethdnMatchId((match !== null) ? match.match_id : '');
		settxtCricproMatchId((match !== null) ? match.cricpro_match_id : '');
		setdrpProvider((match !== null) ? match.match_provider : '');
		setdrpMatchSeries((match !== null) ? match.series_id : '');
		settxtMatchName((match !== null) ? match.match_name : '');
		settxtTeamA((match !== null) ? match.match_team_a : '');
		settxtTeamB((match !== null) ? match.match_team_b : '');
		settxtMatchName((match !== null) ? match.match_name : '');		
		settxtMatchType((match !== null) ? match.match_type : '');
		setdrpMatchStatus((match !== null) ? match.match_status : '');
		
		settxtMatchResult((match !== null) ? match.match_result : '');
		settxtMatchVenue((match !== null) ? match.match_venue : '');
		settxtMatchUrl((match !== null) ? match.match_url : '');
		settxtTeamImageA((match !== null) ? match.team_image_a : '');
		settxtTeamImageB((match !== null) ? match.team_image_b : '');
		
		settxtMatchDate((match !== null) ? new Date(match.match_date) : null);
		console.log("date",match.match_date);

		
	}
	console.log("txt ",txtMatchDate);
	const changeMatchStatus = (matchid = null,status) => {
		//console.log(matchid,status);
		let data = {
			matchid,status,
		}
		
		Api("matches/change-status", constants.prepareData('POST', data)).then((res) => {
			//console.log(res);
			//deleteModal.current.click();
			if(res.status !== 401){
				loadTable();
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn(false);
			}
		});

	}
	const deleteMatch = () => {
		let data = {
			hdnMatchId,
		}
		
		Api("matches/delete", constants.prepareData('POST', data)).then((res) => {
			//console.log(res);
			deleteModal.current.click();
			if(res.status !== 401){
				loadTable();
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn(false);
			}
		});
	}
	
	const resetForm = (form_element) => {
		var validator = $(form_element).validate();
		document.getElementById(form_element.replace(/#/g,'')).reset();
		validator.resetForm();

		setButtonSpinner(false);
		sethdnMatchId('');
		settxtCricproMatchId('');
		setdrpProvider('');
		setdrpMatchSeries('');
		setdrpMatchStatus('');
		settxtMatchUrl('');
		settxtMatchName('');
		settxtTeamA('');
		settxtTeamB('');
		settxtMatchName('');
		settxtMatchType('');
		settxtMatchResult('');
		settxtMatchVenue('');	
		settxtTeamImageA('');
		settxtTeamImageB('');	
		settxtMatchDate(null);

	}
	$(() => {
		$('#filterDate').datepicker({
			autoclose : true,
			format: "dd-mm-yyyy",
			todayHighlight: true,
			
		}).on("changeDate", function(){
			setfilterDate($('#filterDate').datepicker('getFormattedDate'));
		});

		$("#form_match").validate({
			rules: {
				txtCricproMatchId : {
					required: true,
					number:true
				},
				drpMatchSeries : {
					required: true,
				},
				drpProvider : {
					required: true,
				},
				drpMatchStatus : {
					required: true,
				},
				txtMatchName : {
					required: true,
				},
				txtTeamA : {
					required: true,
				},
				txtTeamB : {
					required: true,
				},
				txtMatchType : {
					required: true,
				},
				txtMatchVenue : {
					required: true,
				},
				txtMatchDate : {
					required: true,
				},
			},
			errorElement : 'div',
			errorPlacement: function(error, element) {
				//console.log(element);
				error.appendTo( element.parent('div') );
				error.addClass('invalid-feedback');
			},
			errorClass: "is-invalid",

		});		
	});
	const prevPage = () =>{
		if(cur_page > 1){
			setCurPage(--cur_page)
		}else{ 
			setCurPage(1);
		}
	}
	const nextPage = () =>{
		if(cur_page < max_page){
			setCurPage(++cur_page)
		}else{
			setCurPage(max_page)
		}
	}
	
	const loadTable = () => {
		setSpinner(true);
		let page_length = $("#page_length").val();
		if(page_length == undefined){
			page_length= constants.DEFAULT_RECORD_LIMIT;
		}
		let data = {
			filterDate,
			filterProvider,
			filterMatchSeries,
			search,
			start : (cur_page-1) * page_length,
			length : page_length
		}
		Api('matches', constants.prepareData('POST', data)).then((res) => {
			if(res.status !== 401){
				if(res.hasOwnProperty('list')){
					setResult(res);
					setMaxPage(res.list.total_pages);
					setCurPage((res.list.total_pages != 0 && res.list.total_pages < cur_page ) ? res.list.total_pages : cur_page);
					setSpinner(false);
				}
			}else{
				localStorage.removeItem('admin_auth');
				setIsLoggedIn(false);
			}
		});
	}
	
 	useEffect(() => {
		if(is_logged_in === false){
			navigate('/');
		}
	}, [is_logged_in, navigate])

	
	useEffect(() => {
		loadTable();
	}, [cur_page,filterDate, filterProvider,filterMatchSeries, search])

	const fillTableRow = (m_data, idx) => { 
		//console.log(m_data);
		return <>
			<tr key={m_data.match_id}>
				<td>{m_data.match_id}</td>
				<td>
					{(m_data.match_status == 0) ? <span className='badge bg-danger'><small>Finished</small></span> :''}
					{(m_data.match_status == 1) ? <small><span className='badge bg-primary'>Upcoming</span> </small> :''}
					{(m_data.match_status == 2) ? <small><span className='badge bg-success'>Live</span></small> :''}
				</td>
				<td><small>{m_data.series_name}<br/>{m_data.provider_series_id}</small></td>
				<td><small>{m_data.match_name}<br/>
					{result.provider[m_data.match_provider]} - {m_data.cricpro_match_id} <span className='badge bg-success'><small>{m_data.match_type}</small></span><br/>
					Match URL:- </small>
				</td>
				<td>
					<img width="7%" src={m_data.team_image_a}/>&nbsp;<b className="text-info">{m_data.match_team_a}</b>
					&nbsp;<i className="bi bi-shield text-success"></i>&nbsp;
					<img width="7%" src={m_data.team_image_b}/>&nbsp;<b className="text-muted">{m_data.match_team_b}</b><br/>
					<label className="text-success"><i className="bi bi-trophy"></i><small> {m_data.match_result}</small></label>
				</td>
				<td><small>{m_data.match_dis_date} {m_data.match_dis_end_date}</small><br/>
					<small>Venue: {m_data.match_venue}</small><br/>
					<small>Toss: {m_data.match_toss}</small>
				</td>
				<td>
					<span className="btn form-switch">
						<input className="form-check-input" type="checkbox" name="switchMatchStatus" role="switch" id="switchMatchStatus" checked={(m_data.is_enabled ==1) ? "checked" : ''} onChange={(e)=>changeMatchStatus(m_data.match_id,(m_data.is_enabled == 1) ? "0" : "1")} />
					</span>
					<button className="btn" data-bs-toggle="modal" data-bs-target="#add_edit_match" onClick={() => editMatch(m_data)}><i className="bi bot-edit text-primary"></i></button>

					<button className="btn" onClick={() => sethdnMatchId(m_data.match_id)} data-bs-toggle="modal" data-bs-target="#delete_match"><i className="bi bot-trash text-danger-light"></i></button>
				
				</td>
			</tr>
		</>
	}
	return <>
		<section className="section">
			<div className="row mb-3">
				<div className="col-6 col-md-2">
					<h5 className="page-title">Matches</h5>
				</div>
				
				<div className="col-md-2">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<input type="text" className="form-control" id="filterDate" name="filterDate" placeholder="Select Search Date" defaultValue={filterDate} onChange={(e) => setfilterDate(e.target.value)}/>
							<div className="form-control-icon">
								<i className="bi bot-calendar"></i>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-2">
					<div className="form-group has-icon-left">
						<div className="position-relative">
							<input type="text" className="form-control" placeholder="Search by name" id="search_by_name" onKeyUp={(e) => {setSearch(e.target.value)}}/>
							<div className="form-control-icon">
								<i className="bi bi-search f-color"></i>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-2 mb-3">
					{
						result.hasOwnProperty('provider') &&
							constants.matchProvider(result.provider, setfilterProvider, filterProvider)
					}
				</div>
				<div className="col-md-2 mb-3">
					{
						result.hasOwnProperty('series_list') &&
							constants.drpSeriesList(result.series_list, setfilterMatchSeries, filterMatchSeries)
					}
				</div>
				
				<div className="col-2 col-md-2 d-grid mb-3">
					<button className="btn btn-info" data-bs-toggle="modal" data-bs-target="#add_edit_match" onClick={() => addMatch()}><i className="bi bi-plus-circle-fill pe-1"></i> Add new</button>
				</div>
			</div>
			<table className="table table-striped mb-0 datatable">
				<thead>
					<tr>
						<th>ID</th>
						<th>Status</th>
						<th>Series</th>
						<th>Matches</th>
						<th>Team</th>
						<th>Time</th>
						<th width="160px">Actions</th>
					</tr>
				</thead>
				
			<tbody>
			{
				spinner === true ?
				<><tr><td colSpan={8} className="text-center"><span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> Loading Data..</td></tr></>
				:
				(spinner === false && (result.hasOwnProperty('list') && result.list.total_records > 0) ) ?
					result.list.data.map((sub, idx) => 
						fillTableRow(sub, idx)
					)
				: <><tr><td colSpan={8} className="text-center"> No data found</td></tr></>
			}
				
				
			</tbody>
			</table>
			
			{
				result.hasOwnProperty('list') && 
				constants.pagination(result.list, cur_page, nextPage, prevPage, loadTable)
			}
		</section>

		<div className="modal fade" id="add_edit_match" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="Match" aria-hidden="true">
			<div className="modal-dialog modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">{(!is_edit) ? 'Add New Match' : 'Edit Match'}</h5>
						<button type="button" className="btn" data-bs-dismiss="modal" aria-label="Close" ref={editModal} onClick={ () => resetForm("#form_match") }><i className="bi bi-x-circle-fill"></i></button>
					</div>
					<div className="modal-body">
					<form autoComplete="off" id="form_match" method="post" acceptCharset="utf-8">
						<input type="hidden" name="hdnMatchId" id="hdnMatchId" defaultValue={hdnMatchId}/>
						
						<div className="modal-form">
							
							<div className="row mb-3">
								<div className="col-md-6">
									<label htmlFor="drpMatchProvider" className="form-label">Select Series</label>
									{
										result.hasOwnProperty('series_list') &&
											constants.drpSeriesList(result.series_list, setdrpMatchSeries, drpMatchSeries)
									}
								</div>
								<div className="col-md-3">
									<label htmlFor="drpMatchProvider" className="form-label">Match Provider</label>
									{
										result.hasOwnProperty('provider') &&
											constants.matchProvider(result.provider,setdrpProvider,drpProvider)
									}
								</div>
								<div className="col-md-3">
									<label htmlFor="txtCricproMatchId" className="form-label">Cricpro Match Id</label>
									<input type="text" className="form-control" id="txtCricproMatchId" name="txtCricproMatchId" placeholder="Enter Cricpro Match Id" defaultValue={txtCricproMatchId} onChange={(e) => settxtCricproMatchId(e.target.value)}/>
								</div>
							</div>							
							<div className="row mb-3">
								<div className="col-md-9">
									<label htmlFor="txtMatchName" className="form-label">Match Name</label>
									<input type="text" className="form-control" id="txtMatchName" name="txtMatchName" placeholder="Enter a Match name" defaultValue={txtMatchName} onChange={(e) => settxtMatchName(e.target.value)}/>
								</div>								
								<div className="col-md-3">
									<label htmlFor="txtMatchType" className="form-label">Match Type</label>
									{
										constants.drpMatchType(settxtMatchType,txtMatchType) 
									}
								</div>
							</div>			
							<div className="row mb-3">	
								<div className="col-md-4">
									<label htmlFor="txtMatchDate" className="form-label">Match Date</label>
									<div className="position-relative">
									{ dateInput() }
									</div>
								</div>									
								<div className="col-md-4">
									<label htmlFor="txtTeamA" className="form-label">Team A</label>
									<input type="text" className="form-control" id="txtTeamA" name="txtTeamA" placeholder="Enter Team A" defaultValue={txtTeamA} onChange={(e) => settxtTeamA(e.target.value)}/>
								</div>								
								<div className="col-md-4">
									<label htmlFor="txtTeamB" className="form-label">Team B</label>
									<input type="text" className="form-control" id="txtTeamB" name="txtTeamB" placeholder="Enter Team B" defaultValue={txtTeamB} onChange={(e) => settxtTeamB(e.target.value)}/>
								</div>						
							</div>
							<div className="row mb-3">
								<div className="col-md-9">
									<label htmlFor="txtMatchResult" className="form-label">Match Result</label><small></small>
									<div className="position-relative">
										<input type="text" className="form-control" id="txtMatchResult" name="txtMatchResult" placeholder="Enter Match Result" defaultValue={txtMatchResult} onChange={(e) => settxtMatchResult(e.target.value)}/>
									</div>
								</div>				
								<div className="col-md-3">
									<div className="position-relative12">										
										<label htmlFor="drpMatchStatus" className="form-label">Match Status</label>
										{
											constants.drpMatchStatus(setdrpMatchStatus,drpMatchStatus) 
										}								
									</div>
								</div>				
							</div>	
							<div className="row mb-3">
								<div className="col-md-12">
									<label htmlFor="txtMatchVenue" className="form-label">Match Venue</label>
									<div className="position-relative">
										<input type="text" className="form-control" id="txtMatchVenue" name="txtMatchVenue" placeholder="Enter Match Venue" defaultValue={txtMatchVenue} onChange={(e) => settxtMatchVenue(e.target.value)}/>
									</div>
								</div>								
							</div>					
							<div className="row mb-3">
								<div className="col-md-12">
									<label htmlFor="txtMatchUrl" className="form-label">Match Url</label>
									<input type="text" className="form-control" id="txtMatchUrl" name="txtMatchUrl" placeholder="Enter a Match URL" defaultValue={txtMatchUrl} onChange={(e) => settxtMatchUrl(e.target.value)}/>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-md-11">
									<label htmlFor="txtTeamImageA" className="form-label">Team A Image Url</label>
									<input type="text" className="form-control" id="txtTeamImageA" name="txtTeamImageA" placeholder="Enter Team A Image URL" defaultValue={txtTeamImageA} onChange={(e) => settxtTeamImageA(e.target.value)}/>
								</div>
								<div className="col-md-1">
									<label>&nbsp;</label>
									<div className="position-relative mt-2">
										<img src={txtTeamImageA} alt="no-image"/>
									</div>
								</div>
							</div>
							<div className="row mb-3">
								<div className="col-md-11">
									<label htmlFor="txtTeamImageB" className="form-label">Team B Image Url</label>
									<input type="text" className="form-control" id="txtTeamImageB" name="txtTeamImageB" placeholder="Enter Team B Image URL" defaultValue={txtTeamImageB} onChange={(e) => settxtTeamImageB(e.target.value)}/>
								</div>
								<div className="col-md-1">
									<label>&nbsp;</label>
									<div className="position-relative mt-2">
										<img src={txtTeamImageB} alt="no-image"/>
									</div>
								</div>
							</div>
						</div>
						{
							(button_spinner) ?
							<button type="button" className="btn btn-info ms-5 mb-4 px-4" disabled>
								<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
								Save Match
							</button>
							:
							<button type="button" className="btn btn-info ms-5 mb-4 px-4" onClick={ saveMatch }>Save Match</button>
						}
					</form>
					</div>
				</div>
			</div>
		</div>
		{
			constants.confirmDialog({
				id: "delete_match",
				title : "Delete Match", 
				action : "Delete",
				msg : "Are you sure to delete this match?",
				callback : deleteMatch,
				cancelConfirm : deleteModal,
			})
		}
	</>
}