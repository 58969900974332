import './assets/css/bootstrap.min.css';
import "rsuite/dist/rsuite.min.css";
import './assets/css/bootstrap-icons.css';
import './assets/css/bootstrap-datepicker3.css';

//import './assets/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './App.css';
import AdminApp from './Admin/AdminApp';

function App() {
  return (
      <AdminApp />
  );
}

export default App;